import React, { useEffect, useState } from 'react'
import { SearchGlobalInput } from '@lk-gtcom/ecomlab-components'
import { useDebounce } from '../../common/hooks'
import { Andrey2Https, Andrey2Http } from '../../fetchUrls'
import headers from '../../common/headers'
import { useNavigate } from 'react-router-dom'


const SkuSearch = () => {
    const navigate = useNavigate()
    const [search, setSearch] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [searchData, setSearchData] = useState([])
    const [date, setDate] = useState([new Date().getTime() - 604800000, new Date().getTime()])


    const fetchSearchValue = ([search, page = 1], abortController = new AbortController()) => {
        if (search?.length >= 3) {
            setSearchData([])
            let url
            if (!window.location.hostname.match('localhost')) {
                url = `${Andrey2Https}/api/v2/gtcom/sku/search_string/`
            } else {
                url = `${Andrey2Http}/api/v2/gtcom/sku/search_string/`
            }

            let [date_from, date_to] = date

            date_from = new Date(date_from + 86401000)?.toISOString()?.split('T')?.[0]
            date_to = new Date(date_to)?.toISOString()?.split('T')?.[0]

            const body = JSON.stringify({
                date_from,
                date_to,
                page,
                limit: 15,
                product_search: search
            })


            fetch(url, { body, method: 'POST', headers, signal: abortController.signal })
                .then(res => {
                    if (res.ok) {
                        return res.json()
                    } else {
                        throw Error(JSON.stringify(res.json()))
                    }
                })
                .then(json => {
                    // let dataObj = {}
                    // const keys = Object.keys(json)
                    // keys.forEach((el) => {
                    //     dataObj = {...dataObj, [el?.toLowerCase()]: json?.[el]}
                    // })
                    const product_list = json?.Product.map(({ image, product_name, category, brand, nm_id }) => {
                        return {
                           image :image,
                           product: product_name,
                           category: category, 
                           brand: brand,
                           offer_id: nm_id
                        }
                    } )
                    setSearchData({ product: product_list });

                })
                .catch(err => {
                    if (abortController.signal.aborted) return
                    console.error(err)
                })
        }
    }

    const fetchSearchHandler = useDebounce(fetchSearchValue, 1000)

    useEffect(() => {
        if (search) {
            navigate(`/sku-analysis/general?nmid=${search}`)
        }
    }, [search])

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '1em'
        }}>
            <h2>
                Find product by name or SKU
            </h2>
            <SearchGlobalInput
                {...searchData}
                setSearch={({ nm_id }) => setSearch(nm_id)}
                fetchSearch={fetchSearchHandler}
                setSearchData={e => setSearchData(e)}
                value={searchValue}
            />
        </div>
    )
}

export default SkuSearch